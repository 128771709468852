import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* <h1>Welcome to iLoveMy.coach</h1> */}
    {/* <p>Welcome to ilovemy.coach</p> */}
    {/* <p>Health 🚑 / Sport🏃‍♀️ / Motivation 🚀 and others ...<br/>Find the perfect coach for you!</p> */}
    <p>Health / Sport / Motivation and other personal <span style={{whiteSpace:'nowrap'}}>matters ...</span><br/>Find the perfect coach for you!</p> 
    <p>Our coaches help you to track your life, make improvements and get better. Get motivation 24/7 and start your self-improvement with a little help ;-)</p>
    <p style={{background:'#e1e7fd',borderRadius:'5px',padding:'10px'}}>NEWS:<br/>Drill Sergeant 💂‍♀️, our first coach, will be available very soon for Alexa devices. Stay tuned for updates and a release announcement. 🙃😆😎🙌</p>
    
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}> */}
      {/* <Image /> */}
    {/* </div> */}
    {/* <Link to="/page-2/">Go to page 2</Link> */}
    <Link to="/DrillSergeant/">Drill Sergeant</Link> <br/>
    <Link to="/DrillSergeant/terms">Terms</Link> <br/>
    <Link to="/DrillSergeant/privacy">Privacy</Link> 
  </Layout>
)

export default IndexPage
